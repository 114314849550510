<template>
  <div id="RegisterInit" class="wrap">
    <div v-if="!hasSend">
      <h1 class="title">メールアドレス認証</h1>
      <div class="inputWrap">
        <v-general-text
          labelSize="small"
          label="メールアドレス"
          itemStyle="block"
          maxLength="256"
          :errMessage="errors.mail_address||[]"
          v-model="mailAddress"
        >
        </v-general-text>
      </div>
      <b-alert
      class="errorWrap"
      :show="typeof errors.errorMessage !== 'undefined'"
      variant="warning">{{errors.errorMessage}}</b-alert>
      <div class="btnArea">
        <b-button
          class="send"
          :disabled="!mailAddress"
          @click="send"
        >
          送信
        </b-button>
      </div>
    </div>
    <div class="messageWrap" v-if="!hasSend">
      <div class="messageTitle">注意事項</div>
      <div class="message">
        入力いただいたメールアドレス宛に、登録フォームのURLをお送りします。<br>
        助成申請システムのログイン及びシステムからのメール受信に使うメールアドレスを記入してください。<br>
        「system@kddi-foundation.jp」からのメールを受信できるようにしてから送信してください。
      </div>
    </div>
    <div class="end" v-if="hasSend && !sendErr">
      <h1>メール送信完了</h1>
      <div class="message">
        入力いただいたメールアドレスにメールを送信しました。<br>
        メールに記載されているURLからユーザー登録を行ってください。<br>
        30分以上経過してもメールが届かない場合は、大変お手数ですが再度最初からお試しください。
      </div>
    </div>
    <div class="end" v-if="hasSend && sendErr">
      <h1>送信エラー</h1>
      <div class="message">
        既に複数回メール送信をされているため送信できませんでした。<br>
        お手数ですが時間をおいてから再度最初からお試しください。
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'RegisterInit',
  data() {
    return {
      mailAddress: null,
      hasSend: false,
      sendErr: false,
      errors: [],
    };
  },
  methods: {
    async send() {
      this.$store.dispatch('page/onWaiting');
      const params = { mail_address: this.mailAddress };
      const res = await api.send('/api/user/register/verifyMail', params)
        .catch((error) => {
          if (error.response.status === 400) {
            this.sendErr = true;
            return true;
          }
          this.errors = error.response.data.message;
          return false;
        });
      if (res !== false) {
        this.hasSend = true;
      }
      this.$store.dispatch('page/offWaiting');
    },
  },

};
</script>

<style>
  #RegisterInit .errorWrap{
    width: 400px;
    margin:0 auto;
    margin-bottom: 30px;
  }
</style>
<style scoped>
  .title{
    margin-top: 112px;
    margin-bottom: 70px;
    color: #333333;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .inputWrap{
    width: 430px;
    margin:0 auto;
    display:block;
  }
/* ---送信ボタン--- */
  .send{
    background-color: #122889!important;
    color: #FFFFFF!important;
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
  }
  .btnArea{
    margin-top: 30px;
    text-align: center;
  }
/* ---注意事項--- */
.messageWrap{
  width: 800px;
  height: 160px;
  display: block;
  margin: 90px auto;
  border: solid 1px #6E6E6E;
  box-sizing:content-box;
  text-align: center;
}
.messageWrap .messageTitle{
  display: block;
  width: 800px;
  height: 50px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
  background-color:#6E6E6E;
  padding:8px;
}
.messageWrap .message{
  margin:31px 0;
}
/*完了画面*/
.end{
  margin:auto;
  margin-top: 32.8vh;
  text-align: center;
}
.end h1{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 46px;
}
.error{
  font-size: 80%;
  color: #dc3545;
  display: block;
  text-align: left;
  width: 400px;
  margin: auto;
  margin-top: -10px;
}
</style>
